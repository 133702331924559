import * as React from "react";
import { Link } from "gatsby";
import IconLink from "../components/iconlink";
import Seo from "../components/seo";
import {
  faEnvelope,
  faArrowDown,
  faBox,
} from "@fortawesome/free-solid-svg-icons";
import {
  faGithub,
  faGit,
  faTwitter,
  faLinkedin,
} from "@fortawesome/free-brands-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { StaticImage } from "gatsby-plugin-image";

import "./index.scss";

const IndexPage = () => {
  return (
    <>
      <Seo title="Home" />
      <div id="background">
        <main className="relative top-1/2 md:left-2/3 left-1/2 flex flex-col items-center w-fit h-fit text-white">
          <StaticImage
            src="../images/pfp.png"
            width={300}
            quality={90}
            formats={["auto", "webp", "avif"]}
            alt="My face"
            className="rounded-full"
          />
          <div id="header" className="flex flex-col items-center my-2">
            <h1 className="mb-2 md:text-3xl text-2xl md:font-bold font-semibold text-center ">
              Manuel Altalef
            </h1>
            <p className="md:text-lg text-base text-center">
              Fullstack Developer and Systems Programmer
            </p>
          </div>
          <p className="mt-3 md:text-md text-base text-center">
            Get in touch with me:
          </p>
          <div
            id="icons"
            className="my-3 mb-6 grid gap-y-1.5 sm:grid-cols-4 sm:grid-rows-1 grid-cols-3 grid-rows-2">
            <IconLink
              icon={faEnvelope}
              to="mailto:maltalef@auror.ar"
              textStyle="text-2xl"
            />
            <IconLink
              icon={faGithub}
              to="https://github.com/maltalef101"
              textStyle="text-2xl"
            />
            <IconLink
              icon={faLinkedin}
              to="https://www.linkedin.com/in/manuel-altalef-8ba892174/"
              textStyle="text-2xl"
            />
            <IconLink
              icon={faTwitter}
              to="https://twitter.com/maltalef64"
              textStyle="text-2xl"
            />
            {/* <IconLink icon={faGit} to="https://git.auror.ar" style="text-2xl" /> */}
          </div>
          <Link to="/projects">
            <div className="p-2 bg-sky-600 rounded-xl flex content-center">
              <FontAwesomeIcon icon={faBox} className="m-1 text-xl" />
              <p className="ml-1.5 text-lg">My projects</p>
            </div>
          </Link>
        </main>
        {/* <div className="fixed w-full bottom-0 flex justify-center"> */}
        {/*   <div */}
        {/*     id="scroll-down-button" */}
        {/*     className="px-1.5 mb-2 animate-bounce rounded-full border-zinc-800 border-2 bg-zinc-800"> */}
        {/*     <FontAwesomeIcon */}
        {/*       icon={faArrowDown} */}
        {/*       color="#FFFFFF" */}
        {/*       className="shrink-0" */}
        {/*     /> */}
        {/*   </div> */}
        {/* </div> */}
      </div>
    </>
  );
};

export default IndexPage;
