import * as React from "react";
import PropTypes from "prop-types";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import "./iconlink.scss";

const IconLink = ({ icon, to, textStyle }) => {
  return (
    <a href={to} rel="noopener noreferrer" target="_blank">
      <FontAwesomeIcon
        icon={icon}
        className={`px-2.5 transition duration-200 ease-in-out hover:-translate-y-1 hover:scale-125 ${textStyle}`}
        color="#FFFFFF"
      />
    </a>
  );
};

IconLink.propTypes = {
  icon: PropTypes.object.isRequired,
  to: PropTypes.string,
  textStyle: PropTypes.string,
};

export default IconLink;
